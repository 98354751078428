<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Rekomendasi Pembelian</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Pembelian / Rekomendasi Pembelian</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="mb-2">
        <div class="grid">
          <div class="col-12">
            <form>
              <div class="formgrid grid">
                <div class="field col-12">
                  <div class="font-bold">Filter</div>
                </div>
                <div class="field col-12 md:col-4">
                  <Dropdown
                    v-model="form.supp_id"
                    :options="list_supplier"
                    optionValue="id"
                    optionLabel="name"
                    placeholder="Pilih Supplier"
                    class="w-full"
                    :class="{
                      'p-invalid': v$.form.supp_id.$invalid && submitted,
                    }"
                    filter
                    showClear
                    @change="selectSupp"
                  />
                  <small
                    v-if="
                      (v$.form.supp_id.$invalid && submitted) ||
                      v$.form.supp_id.$pending.$response
                    "
                    class="p-error"
                    >{{ v$.form.supp_id.required.$message }}</small
                  >
                </div>
                <div class="field col-12 md:col-2">
                  <!-- <Dropdown
                    v-model="form.periode"
                    :options="list_periode"
                    optionValue="value"
                    optionLabel="label"
                    placeholder="Pilih Periode"
                    class="w-full"
                    filter
                  /> -->
                </div>

                <div class="field col-12 md:col-6 grid justify-content-end">
                  <Button
                    :loading="isLoadingPreview"
                    class="p-button-success mr-2"
                    @click="onPreview(!v$.$invalid)"
                    type="button"
                    icon="pi pi-table"
                    label="Preview"
                    style="height: 40px"
                  />
                  <Button
                    :loading="isLoadingExport"
                    @click="onPrint(!v$.$invalid)"
                    type="button"
                    icon="pi pi-file-pdf"
                    label="Cetak"
                    class="mr-2"
                    style="height: 40px"
                  />
                  <Button
                    :loading="isLoadingCreatePurchaseOrder"
                    @click="onCreatePurchaseOrder(!v$.$invalid)"
                    type="button"
                    icon="pi pi-plus"
                    label="Purchase Order"
                    class="mr-2"
                    :disabled="!hasItemSelected"
                    style="height: 40px"
                  />
                  <Button
                    type="reset"
                    icon="pi pi-refresh"
                    label="Reset"
                    class="p-button-outlined ml-2"
                    @click="clearFilter()"
                    style="height: 40px"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <DataTable
        v-model:filters="filters"
        :value="items"
        :row-hover="true"
        :loading="isLoadingPreview"
        :scrollable="true"
        class="p-datatable-sm"
      >
        <template #empty>
          <div class="text-center">Tidak ada data.</div>
        </template>
        <template #loading>
          <div class="text-center">Memuat data, harap tunggu...</div>
        </template>

        <!-- Checkbox Column -->
        <!-- <Column selectionMode="multiple" /> -->
        <Column>
          <template #body="slotProps">
            <Checkbox
              v-model="slotProps.data.selected"
              :binary="true"
              @click="check(slotProps.data)"
            />
          </template>
          <template #header>
            <Checkbox v-model="selectedAll" :binary="true" @click="checkAll" />
          </template>
        </Column>
        <Column
          field="Category"
          header="Kategori"
          filterField="ctg_id"
          style="min-width: 15rem"
          :showFilterMenu="false"
          sortable
        />
        <Column
          field="name"
          header="Nama"
          style="min-width: 15rem"
          sortable
          :showFilterMenu="false"
        />
        <Column
          field="sku"
          header="Sku"
          style="min-width: 12rem"
          sortable
          :showFilterMenu="false"
        />
        <Column field="umin" header="Stock Min" style="min-width: 12rem" />
        <Column field="umax" header="Stock Maks." style="min-width: 12rem" />
        <Column field="Stock" header="Stock" style="min-width: 8rem"> </Column>
        <Column
          field="PrcRec"
          header="Rekomendasi Stok"
          style="min-width: 12rem"
        />
      </DataTable>
    </div>
    <Dialog
      header="Hapus Order Pembelian"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Order Pembelian <strong>{{ form.pr_no }}</strong> akan dihapus.
          Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeletePurchaseOrder"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <Dialog
      :header="pdfTitle"
      v-model:visible="dialogViewer"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '90vw' }"
      :modal="true"
    >
      <div>
        <embed
          :src="pdfSource"
          type="application/pdf"
          width="100%"
          height="600px"
        />
      </div>
      <template #footer>
        <Button
          type="button"
          label="Download"
          icon="pi pi-download"
          class="p-button-text mr-2"
          @click="onDownloadPdf"
        />
        <Button
          type="button"
          label="Print"
          icon="pi pi-print"
          @click="onPrintPdf"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import SupplierService from '@/services/SupplierService'
import ItemService from '@/services/ItemService'
import KategoriService from '@/services/KategoriService'
import PurchaseOrderService from '@/services/PurchaseOrderService'

// import FxTable from '@/components/pembelian/GridPo'
// import CalendarFilter from '@/components/CalendarFilter.vue'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency, formatDate } from '@/helpers/index'
import { saveAs } from 'file-saver'
import { helpers, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: 'PurchaseOrder',
  components: {
    Hotkey,
  },
  data() {
    return {
      submitted: false,
      selectedAll: false,
      selected: [],
      isLoadingCreatePurchaseOrder: false,
      isLoadingExport: false,
      isLoadingPreview: false,
      kategoriService: null,
      supplierService: null,
      purchaseOrderService: null,
      itemService: null,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      items: [],
      form: {
        supp_id: '',
        periode: 30,
      },
      supplier: {},
      purchaseOrder: {},
      list_supplier: [],
      list_kategori: [],
      list_periode: [
        {
          label: '30 Hari',
          value: 30,
        },
        {
          label: '60 Hari',
          value: 60,
        },
        {
          label: '90 Hari',
          value: 90,
        },
      ],
      list_status: [
        { value: 'draft', label: 'Draft', level: 0 },
        { value: 'done', label: 'Done', level: 1 },
      ],
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'Category,name',
        sortOrder: '1',
        filters: null,
      },
      filters: {
        ctg_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
        supplier: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sku: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      pdfSource: '',
      pdfFilename: '',
      pdfTitle: 'PDF Viewer ',
      dialogViewer: false,
    }
  },
  computed: {
    hasItemSelected() {
      const itemSelected =
        this.selected && this.selected.length > 0 ? true : false
      return itemSelected
    },
  },

  created() {
    this.supplierService = new SupplierService()
    this.itemService = new ItemService()
    this.kategoriService = new KategoriService()
    this.purchaseOrderService = new PurchaseOrderService()
  },
  async mounted() {
    this.supplierService
      .get()
      .then((res) => {
        this.list_supplier = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Supplier', this)
      })
      .finally(() => (this.isLoading = false))
    this.kategoriService
      .get()
      .then((res) => {
        this.list_kategori = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Kategori', this)
      })
  },
  methods: {
    selectSupp(e) {
      const supp_id = e.value
      const idx = this.list_supplier.findIndex((item) => item.id === supp_id)
      if (idx != -1) {
        this.supplier = this.list_supplier[idx]
      }
    },
    onPreview(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.loadData()
    },
    onPrint(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.isLoadingExport = true
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      // const filters = { supp_id: this.form.supp_id }

      this.purchaseOrderService
        .recommendationPrint(sortField, sortOrder, this.form.supp_id)
        .then((res) => {
          const blob = new Blob([res.data], { type: 'application/pdf' })
          // const blob = new Blob([{}], { type: 'application/pdf' })
          const pdfUrl = URL.createObjectURL(blob)
          this.pdfSource = pdfUrl + '#toolbar=0'
          this.pdfFilename = 'recommendation-order.pdf'
          this.pdfTitle = 'PDF Viewer (' + this.pdfFilename + ')'
          this.dialogViewer = true
        })
        .catch((err) => {
          errorHandler(err, 'Print Order Pembelian', this)
        })
        .finally(() => (this.isLoadingExport = false))
    },
    onCreatePurchaseOrder() {
      // const withQTY = this.selected
      const withQTY = this.selected.filter((item) => {
        return item.PrcRec > 0
      })

      const purchaseOrder = {
        supplier: this.supplier,
        payment: this.supplier.payment,
        term_id: this.supplier.term_id,
        supp_id: this.form.supp_id,
        details: withQTY.map((item) => ({
          ...item,
          item_id: item.id,
          unit_b: item.unit_b,
          unit_s: item.unit_s,
          cost_per: item.unit_s,
          ubox: item.ubox,
          item_name: item.name,
          item_barcode_1: item.barcode_1,
          item_sku: item.sku,
          item_mrg: item.std_mrg,
          qty_b: 0, //Math.floor(item.PrcRec / item.ubox),
          qty_s: item.PrcRec, //item.PrcRec - Math.floor(item.PrcRec / item.ubox) * item.ubox,
          cost: item.cost,
          tax: 0,
          disc_reg: 0,
          disc_ext: 0,
        })),
      }
      localStorage.setItem('purchaseOrder', JSON.stringify(purchaseOrder))
      this.$router.push({ name: 'PurchaseOrderAdd' })
    },
    clearFilter() {
      this.submitted = false
      this.options.filters = null
      this.form.supp_id = null
      this.selected = []
      this.items = []
    },
    formatCurrency(val) {
      return formatCurrency(val, 2, 2)
    },
    formatDate(value) {
      return formatDate(value)
    },
    onRequestData(e) {
      this.options.filters = { supp_id: e }
    },
    loadData() {
      this.isLoading = true
      // const page = this.options.page
      // const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      // const filters = { supp_id: this.form.supp_id }
      this.selected = []
      this.selectedAll = false
      this.purchaseOrderService
        .recommendation(sortField, sortOrder, this.form.supp_id)
        .then((res) => {
          this.items = res.data
          this.totalRecords = res.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Rekomendasi Pembelian', this)
        })
        .finally(() => (this.isLoading = false))
    },

    onPrintData() {
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      // const filters = { supp_id: this.form.supp_id }

      this.purchaseOrderService
        .recommendationPrint(sortField, sortOrder, this.form.supp_id)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Purchase-Recommendation.pdf')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Print Rekomendasi Pembelian', this)
        })
    },
    check(data) {
      data.selected = !data.selected
      if (data.selected == true) {
        this.selected.push(data)
      } else {
        const idx = this.selected.findIndex((item) => item.id === data.id)
        this.selected.splice(idx, 1)
      }
    },
    checkAll() {
      this.selectedAll = !this.selectedAll
      this.selected = []
      this.items.forEach((item) => {
        item.selected = this.selectedAll
        if (item.selected) {
          this.selected.push(item)
        }
      })
    },
    onDownloadPdf() {
      saveAs(this.pdfSource, this.pdfFilename)
    },
    onPrintPdf() {
      const iframe = document.createElement('iframe')
      iframe.src = this.pdfSource
      iframe.style.display = 'none'
      document.body.appendChild(iframe)

      // Use onload to make pdf preview work on firefox
      iframe.onload = () => {
        iframe.contentWindow.focus()
        iframe.contentWindow.print()
      }
    },

    onEditData(data) {
      this.$router.push({ name: 'PurchaseOrderEdit', params: { id: data.id } })
    },

    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeletePurchaseOrder() {
      this.itemService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 1) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data Order Pembelian',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Order Pembelian', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.$router.push({ name: 'PurchaseOrderAdd' })
      }
    },
    /* onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col))
    }, */
  },
  validations() {
    return {
      form: {
        supp_id: {
          required: helpers.withMessage('Supplier harus diisi.', required),
        },
      },
    }
  },
}
</script>
